<style lang="scss" scoped>
.line {
	margin: 0.3rem 0.3rem;
	height: 1px;
	background: $line_color;
}
::v-deep .van-cell {
	padding-left: 0.3rem;
	padding-right: 0.3rem;
}
::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
}
::v-deep .cell_value {
	color: $font_color_val;
	font-size: 0.24rem;
}
::v-deep .van-field__body input {
	color: $font_color_val;
}
::v-deep .no_link .van-icon-arrow {
	color: #fff;
}
.btns {
	margin-top: 0.6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	.btn {
		width: 3rem;
		height: 0.9rem;
		font-size: 0.3rem;
		line-height: 0.9rem;
		text-align: center;
		border-radius: 0.08rem;
	}
	.btn_cancel {
		border: 1px solid $color_main;
		color: $color_main;
		background-color: #fff;
		margin-right: 0.4rem;
	}
	.btn_sure {
		border: 1px solid $color_main;
		color: #fff;
		background-color: $color_main;
	}
}
</style>

<template>
	<div class="add_passport_user">
		<top-nav v-if="!this.onlyShowForm" @back="back">{{ $t('addInsured') }}</top-nav>
		<van-form ref="form" input-align="right" error-message-align="right">
			<van-cell-group :border="isShowBorder">
				<van-field :label="$t('name')" v-model="userInfo.userName" :formatter="nameFormatter" class="no_link" label-class="cell_title" value-class="cell_value" :border="false" is-link :rules="rules.userName" :placeholder="`${$t('name')}${isShowInsuranceCode?'/姓名':''}`" />
				<van-field :label="$t('passportNo')" v-model="userInfo.cardNum" :formatter="nameFormatter" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" :rules="rules.cardNum" :placeholder="`${$t('passportNo')}${isShowInsuranceCode?'/港澳台证件号':''}`" />
				<van-field :label="$t('birthday')" :value="userInfo.birthday" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" :rules="rules.birthday" :placeholder="`${$t('checkBirthday')}${isShowInsuranceCode?'/出生日期':''}`" @click="birthdayPop = true" />
				<van-field :label="$t('sex')" :value="userInfo.sex" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" :rules="rules.sex" :placeholder="`${$t('selectGender')}${isShowInsuranceCode?'男M/女F':''}`" @click="sexPop = true" />
				<van-field :label="$t('mobile')" v-model="userInfo.phone" name="phone" label-class="cell_title" :formatter="mobileFormatter" value-class="cell_value" :border="false" class="no_link" @input="phoneChange" :rules="rules.phone" :placeholder="`${$t('notRequired')}${isShowInsuranceCode?'/手机号选填':''}`" />
				<van-field :label="$t('insuranceCode')" v-if="isShowInsuranceCode" v-model="userInfo.insuranceCode" name="insuranceCode" label-class="cell_title" value-class="cell_value" :border="false" class="no_link" :rules="rules.insuranceCode" :placeholder="`${$t('fillInsuranceCode')}${isShowInsuranceCode?'/保险编码':''}`" />
				<div v-if="!this.onlyShowForm" class="line"></div>
			</van-cell-group>
		</van-form>

		<div class="btns" v-if="!this.onlyShowForm">
			<div class="btn btn_cancel" @click="cancel" v-text="$t('cancel')">取消</div>
			<div class="btn btn_sure" @click="sure" v-text="$t('ok')">确定</div>
		</div>

		<!-- 弹出层 -->
		<!-- 出生日期 -->
		<van-popup v-model="birthdayPop" position="bottom" :style="{ height: '30vh' }">
			<van-datetime-picker v-model="birthday" type="date" :title="$t('selectYMD')" :confirm-button-text="$t('ok')" :cancel-button-text="$t('cancel')" @cancel="birthdayPop = false" @confirm="birthdayCheck" visible-item-count="3" :min-date="birthdayMinDate" />
		</van-popup>
		<!-- 性别 -->
		<van-popup v-model="sexPop" position="bottom" :style="{ height: '30vh' }">
			<van-picker :title="$t('sex')" :confirm-button-text="$t('ok')" :cancel-button-text="$t('cancel')" show-toolbar value-key="label" :visible-item-count="3" :columns="sexList" @confirm="sexCheck" @cancel="sexPop = false" />
		</van-popup>
	</div>
</template>

<script>
import { Cell, CellGroup, Popup, Picker, Calendar, Field, DatetimePicker, Checkbox, Form,Toast } from 'vant';
import { sexs } from '@/config/fixedParams';
import regular from '@/assets/js/regular';
import Mtils from 'mtils';

export default {
	name: 'addPassportUser', // 添加护照类型的被保险人
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Calendar.name]: Calendar,
		[Field.name]: Field,
		[DatetimePicker.name]: DatetimePicker,
		[Checkbox.name]: Checkbox,
		[Form.name]: Form,
	},
	props: {
		onlyShowForm: {
			type: Boolean,
			required: false,
			default: false,
		},

		// 是否显示保险编码
		isShowInsuranceCode: {
			type: Boolean,
			required: false,
			default: false,
		},

		isShowBorder: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {
			// 投保人信息
			userInfo: {
				userName: '',
				cardType: '02',
				cardNum: '',
				birthday: '',
				sex: '',
				phone: '',
				insuranceCode: '',
			},
			rules: {
				userName: [
					{ required: true, message: '' },
					{ pattern: regular.name1, message: '只能输入中文或英文以及·' },
					{ pattern: regular.name2, message: '至少2个中文或4个英文字母' },
					{ validator: this.nameCheck, message: '·不能出现在首尾' },
				],
				cardNum: [
					{ required: true, message: '' },
					{ validator: this.cardNumCheck, message: '护照号码有误' },
				],
				birthday: [{ required: true }],
				sex: [{ required: true }],
				phone: [{ validator: this.phoneCheck, message: '请填写正确的手机号码', trigger: 'onBlur' }],
				insuranceCode: [
					{ required: true, message: '' },
					{ pattern: /^[A-z0-9]{8}$/, message: '请输入8位保险编码' },
				],
			},
			birthdayPop: false,
			birthdayMinDate: new Date('1900/01/01'),
			birthday: new Date('1990/01/01'),
			sexPop: false,
			sexList: [],

			hasBirthday: false,

			fromPathName: '',
		};
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.fromPathName = from.name;
		});
	},
	created() {
		this.initSexList();
	},
	methods: {
		// 获取表单填写内容
		getFormValue(isResetValidate = false) {
			return new Promise(resolve => {
				this.$refs.form
					.validate()
					.then(() => {
						resolve(this.userInfo);
					})
					.catch(err => {
						if (isResetValidate) {
							if (this.userInfo.userName || this.userInfo.cardNum || this.userInfo.birthday || this.userInfo.sex || this.userInfo.phone) {
								this.$store.commit("set_scrollPosition",'assured')
								Toast.fail('被保险人信息有误');
							} else {
								this.$refs.form.resetValidation();
								resolve();
							}
						}
					});
			});
		},

		// 重置表单
		resetForm() {
			this.$set(this, 'userInfo', {
				userName: '',
				cardType: '02',
				cardNum: '',
				birthday: '',
				sex: '',
				phone: '',
				insuranceCode: '',
			});
		},

		// 初始化性别列表
		initSexList() {
			this.sexList = sexs.map(item => {
				return {
					label: this.sexLanguage(item.label),
					value: this.sexLanguage(item.value),
				};
			});
		},

		sexLanguage(text) {
			if (text === '男') {
				return this.$t('man');
			}
			return this.$t('female');
		},

		// 投保人名称检测
		nameCheck(val) {
			if (val[0] === '·' || val[val.length - 1] === '·') {
				return false;
			}
			return true;
		},

		// 投保人姓名格式化
		nameFormatter(v) {
			return v.toLocaleUpperCase();
		},

		back() {
			let query = this.$route.query;
			delete query.userInfo;
			this.$router.push({
				path: this.fromPathName,
				query,
			});
		},

		birthdayCheck(val) {
			this.userInfo.birthday = this.$base.dateFormater(val);
			this.birthdayPop = false;
		},

		sexCheck(val) {
			this.userInfo.sex = val.label;
			this.sexPop = false;
		},

		cancel() {
			this.back();
		},

		phoneCheck(val) {
			if (!val) {
				return true;
			}
			return regular.phone.test(val);
		},

		// 手机号码格式化
		mobileFormatter(v) {
			return String(v).replace(/ /g, '');
		},

		// 护照号码校验方法
		cardNumCheck(val) {
			return regular.passport.test(val) && !regular.repeatReg7.test(val) && !regular.sequentialReg7.test(val);
		},

		phoneCheck(val) {
			if (!val) {
				return true;
			}
			return regular.phone.test(val);
		},

		phoneChange(val) {
			if (this.rules.userName[1].pattern.test(this.userInfo.userName) && this.rules.userName[2].pattern.test(this.userInfo.userName) && this.nameCheck(this.userInfo.userName) && this.cardNumCheck(this.userInfo.cardNum) && this.userInfo.birthday && this.userInfo.sex && regular.phone.test(val)) {
				this.$emit('infoIsReady', this.userInfo);
			}
		},

		sure() {
			this.$refs.form.validate().then(() => {
				this.$router.push({
					path: this.fromPathName,
					query: {
						...this.$route.query,
						userInfo: JSON.stringify(this.userInfo),
					},
				});
			});
		},
	},
};
</script>
